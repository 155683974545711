<template>
  <div class="container mw-fit-content">
    <Table
      :thead="thead"
      :data="data"
      :search="false"
      :excel="true"
      :searchBox="true"
      tableTitle="Kullanıcılar"
    >
      <template v-slot:button>
        <router-link :to="{ name: 'User' }" class="btn btn-filled-primary">
          Kullanıcı Ekle
          <i class="icon icon-add ml-10"></i>
        </router-link>
      </template>
      <template v-slot:cell-edit="{ row: data }">
        <router-link
          :to="{ name: 'UserEdit', params: { id: data.id } }"
          class="btn btn-empty-primary minw-auto"
        >
        <i class="icon icon-message-edit"></i>
        </router-link>
      </template>
      <template v-slot:cell-fullname="{ row: data }">
        {{ data.fullname }}
      </template>
      <template v-slot:cell-email="{ row: data }">
        {{ data.email }}
      </template>
      <template v-slot:cell-role="{ row: data }">
        {{ data.role }}
      </template>
      <template v-slot:cell-delete="{ row: data }">
        <button class="btn btn-empty-danger" @click="deleteUser(data.id)">
          <i class="icon icon-trash"></i>
        </button>
      </template>
    </Table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.getUsers();
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Kullanıcılar",
        path:"/users"
      },
    ]);
  },
  methods: {
    deleteUser(userId) {
      this.$axios
        .delete(`/user/${userId}/`)
        .then(()=>{
          this.getUsers()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getUsers() {
      await this.$axios.get("/user/").then((response) => {
        this.data = []
        response.data.filter((user) => {
          this.data.push({
            id: user.id,
            fullname: `${user.given_name} ${user.family_name}`,
            email: user.email,
            role: user.groups.map((role) => role).join(", "),
          });
        });
      });
    },
  },
  setup() {
    const thead = [
      {
        label: "Düzenle",
        value: "edit",
        searchable: false,
      },
      {
        label: "Kullanıcı Adı Soyadı",
        value: "fullname",
        searchable: true,
      },
      {
        label: "Email",
        value: "email",
        searchable: true,
      },
      {
        label: "Rol",
        value: "role",
        searchable: true,
      },
      {
        label: "Sil",
        value: "delete",
        searchable: false,
      },
    ];
    return {
      thead,
    };
  },
};
</script>